import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

const ComunityScholarship = () => (
  <section className="comunityscholarship-section">
    <div className="comunityscholarship-content">
      <div className="comunityscholarship-box-title-grid">
        <div />
        <div className="comunityscholarship-cube" />
        <p className="comunityscholarship-title">Becas Comunidad</p>
        <div className="comunityscholarship-cube" />
        <div />
      </div>
      <p className="comunityscholarship-title-cenhies">CENHIES</p>
      <div className="comunityscholarship-grid">
        <div className="comunityscholarship-icon-div">
          <p className="comunityscholarship-icon-text">Beca Académica</p>
          <div className="comunityscholarship-icon-box">
            <div className="comunityscholarship-icon-before" />
            <StaticImage
              placeholder="blurred"
              className="comunityscholarship-icon"
              alt=""
              src="../images/Académica.png"
            />
          </div>
        </div>
        <div className="comunityscholarship-description-grid">
          <p className="comunityscholarship-text">
            Está dirigida a las y los alumnos inscritos en cualquiera de
            nuestros niveles académicos que obtengan un promedio mayor a 8.5 y
            que cumplan con los siguientes requisitos: <br />
            <br />
            <b>1.</b> Haber cursado por lo menos un ciclo escolar en CENHIES.{" "}
            <br />
            <b>2.</b> Realizar su solicitud el día y en el horario establecidos
            por el área de becas. <br />
            <b>3.</b> Entregar en tiempo y forma los documentos que establece la
            convocatoria. <br />
            <b>4.</b> El descuento dependerá de su desempeño académico y del
            estudio socioeconómico.
          </p>
          <p className="comunityscholarship-text">
            <b>IMPORTANTE:</b> El trámite para secundaria, se debe realizar
            directamente en la SEPH. La convocatoria se publicará en los medios
            de información oficiales de dicho organismo.
            <br /> <br />
            <b>No acumulable con otra promoción</b>
          </p>
        </div>
      </div>
      <div className="comunityscholarship-grid">
        <div className="comunityscholarship-icon-div">
          <p className="comunityscholarship-icon-text">Beca Familiar</p>
          <div className="comunityscholarship-icon-box">
            <div className="comunityscholarship-icon-before" />
            <StaticImage
              placeholder="blurred"
              className="comunityscholarship-icon"
              alt=""
              src="../images/Familiar.png"
            />
          </div>
        </div>
        <div className="comunityscholarship-description-grid">
          <p className="comunityscholarship-text">
            Cuando están inscritos dos o más integrantes de una familia, el que
            esté matriculado en el nivel más avanzado, gozará de un 10% de
            descuento en su colegiatura mensual. <br />
            <br />
            <b>Nota:</b>Los familiares deben ser hermanos(as), hijos (as) o
            cónyuges
            <br /> <br />
            <b>No acumulable con otra promoción</b>
          </p>
        </div>
      </div>
      <div className="comunityscholarship-grid">
        <div className="comunityscholarship-icon-div">
          <p className="comunityscholarship-icon-text">Beca Lealtad</p>
          <div className="comunityscholarship-icon-box">
            <div className="comunityscholarship-icon-before" />
            <StaticImage
              placeholder="blurred"
              className="comunityscholarship-icon"
              alt=""
              src="../images/Lealtad.png"
            />
          </div>
        </div>
        <div className="comunityscholarship-description-grid">
          <p className="comunityscholarship-text">
            Si cursaste con nosotros nivel básico y deseas continuar tu
            formación académica en <b>CENHIES</b>, tendrás{" "}
            <b>30% de descuento</b> en todas tus parcialidades durante el primer
            semestre, en los niveles <b>medio superior o superior.</b> <br />
            <br />
            Para conservar este beneficio debes de mantener un promedio mínimo
            de 8.5 y ser alumno regular.
            <br /> <br />
            En el caso de las o los egresados de nivel superior que
          </p>
          <p className="comunityscholarship-text">
            decidan estudiar un posgrado,{" "}
            <b>el descuento mensual será del 10%.</b>
            <br /> <br />
            <b>No acumulable con otra promoción</b>
          </p>
        </div>
      </div>
      <div className="comunityscholarship-grid">
        <div className="comunityscholarship-icon-div">
          <p className="comunityscholarship-icon-text">
            Beca por Pago Anticipado
          </p>
          <div className="comunityscholarship-icon-box">
            <div className="comunityscholarship-icon-before" />
            <StaticImage
              placeholder="blurred"
              className="comunityscholarship-icon"
              alt=""
              src="../images/Pago Anticipado.png"
            />
          </div>
        </div>
        <div className="comunityscholarship-description-grid">
          <p className="comunityscholarship-text">
            Adquieres un descuento del 10% en el costo total del ciclo escolar,
            pagándolo por adelantado y en una sola exhibición. <br />
            <br />
            <b>No acumulable con otra promoción</b>
          </p>
        </div>
      </div>
      <div className="comunityscholarship-grid">
        <div className="comunityscholarship-icon-div">
          <p className="comunityscholarship-icon-text">
            Beca por Mérito Académico
          </p>
          <div className="comunityscholarship-icon-box">
            <div className="comunityscholarship-icon-before" />
            <StaticImage
              placeholder="blurred"
              className="comunityscholarship-icon"
              alt=""
              src="../images/Merito Académico.png"
            />
          </div>
        </div>
        <div className="comunityscholarship-description-grid">
          <p className="comunityscholarship-text">
            Las y los egresados de nivel licenciatura que obtengan un promedio
            general de 9.5 o mayor, CENHIES les otorga un{" "}
            <b>descuento especial</b> en su trámite de titulación.
            <br /> <br />
            <b>No acumulable con otra promoción</b>
          </p>
        </div>
      </div>
      <div className="comunityscholarship-grid">
        <div className="comunityscholarship-icon-div">
          <p className="comunityscholarship-icon-text">
            Aprovecha a tus Cuates
          </p>
          <div className="comunityscholarship-icon-box">
            <div className="comunityscholarship-icon-before" />
            <StaticImage
              placeholder="blurred"
              className="comunityscholarship-icon"
              alt=""
              src="../images/Cuates.png"
            />
          </div>
        </div>
        <div className="comunityscholarship-description-grid">
          <p className="comunityscholarship-text">
            Por cada persona que recomiendes y se inscriba en cualquiera de
            nuestros niveles académicos, recibirás un descuento del{" "}
            <b>25% en una sola mensualidad.</b>
            <br />
            <br />
            El aspirante deberá venir acompañado por la o el alumno que lo
            recomendó, para que ambos puedan gozar de este descuento.
            <br /> <br />
            <b>Única promoción acumulable.</b>
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default ComunityScholarship
