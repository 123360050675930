import * as React from "react"
import ComunityScholarship from "../components/ComunityScholarship"
import DiscountServices from "../components/DiscountServices"

import Seo from "../components/Seo"

const ScholarshipPage = () => (
  <>
    <Seo title="Becas Comunidad" />
    <ComunityScholarship />
    <DiscountServices />
  </>
)

export default ScholarshipPage
